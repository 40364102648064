import React from 'react';
import 'foundation-sites/dist/css/foundation.min.css';
import { Grid, Cell } from 'react-foundation';
import './App.css';
import logoImage from './logo.png';
import heroImage from './hero2.jpg';
import secondBackgroundImage from './second-bg.jpg';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cookiesAccepted: false,
      readTermsVisible: false,
    };
  }

  componentDidMount() {
    try {
      if (document.cookie.split(';').filter(function(item) {
        return item.trim().indexOf('rnd_training_cookies_accepted=') == 0
      }).length) {
        this.setState({ cookiesAccepted: true });
        console.log('cookies already accepted');
        this.initGA();
      }
    } catch (e) { console.log(e) }
  }

  initGA = () => {
    try {
      /*eslint-disable */
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        
        ga('create', 'UA-89720326-2', 'auto');
        ga('send', 'pageview');
      
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KCXXRJM');
      /*eslint-enable */
    } catch (e) { console.log(e) }
  }

  renderTerms = () => {
    return (
      <Grid className="display">
        <Cell small={12}>
          <div className="terms">
            <button className="cta" onClick={ () => { this.setState({ readTermsVisible: false }) } }>X &nbsp;Sulje</button>
            
            <h1>Tietosuojaseloste</h1>
            <h1>RND Works Website Privacy Policy</h1>
            <article>
              <p>Last updated:&nbsp;<time>February 20, 2019</time></p>
              <section>
                <h2>1. Common terms</h2>
                <p>Common terms referenced in this document:</p>
                <ul>
                <li>You, The user – The individual using the DocScanner apps</li>
                <li>Metrics – Anonymised usage analytics and anonymised crash data</li>
                </ul>
              </section>
              <section>
                <h2>2. Privacy policy</h2>
                <p>This document describes a policy agreement between you (the user) and RND Works Oy. By using this website, you accept the privacy policy described in this document.</p>
              </section>
              <section>
                <h2>3. Controller</h2>
                <p>RND Works Oy<br/>
                Hakamäenkuja 1<br/>
                FI-01510 VANTAA<br/>
                Business ID:2742151-8<br/>
                Contact Person: Ilari Laitinen<br/>
                Contact Email: ilari@rnd.works
                </p>
              </section>
              <section>
                <h2>4. Processing of personal data</h2>
                <section>
                <h3>4.1 Consent</h3>
                <p>By using this website, you (the user) consent to the collection and usage of metrics.</p>
              </section>
              <section>
                <h3>4.2 Usage of personal data</h3>
                <ul>
                <li>Improvement and troubleshooting the website through metrics.</li>
                <li>Market and usage research through metrics.</li>
                </ul>
              </section>
              <section>
                <h3>4.3 Sources</h3>
                <p>Metrics are collected automatically during use of this website.</p>
              </section>
              <section>
                <h3>4.4 Automated individual decision-making</h3>
                <p>The user’s personal data is not used for profiling or any other application of automated individual decision-making.</p>
              </section>
              <section>
                <h3>4.5 Disclosure</h3>
                <p>The user’s personal data may be disclosed when required by law.</p></section>
              </section>
              <section>
                <h2>5. Storage of personal data</h2>
              <section>
                <h3>5.1 Access to personal data</h3>
                <p>Metrics are stored on 3rd party services of Google Analytics. 3rd party data access is covered by their respective privacy policies. Access to metrics is provided to RND Works Inc developers and marketing personnel.</p>
              </section>
              <section>
                <h3>5.2 System logs</h3>
                <p>RND Works website does not keep separate logs aside from metrics.</p>
              </section>
              <section>
                <h3>5.3 Data security</h3>
                <p>Gathered metrics are only available via encrypted connections (HTTPS, SSH). Physical copies of the the user’s personal data may be created if required by law.</p></section>
              </section>
              <section>
                <h2>6. Contents of collected data</h2>
              <section>
                <h3>6.1 User data collected</h3>
                <ul>
                <li>Anonymised usage analytics</li>
                </ul>
              </section>
              </section>
              <section>
                <h2>7. User rights</h2>
                <p>Any personal data user rights issues for data stored by a 3rd party must be rectified within their respective services. Metrics are anonymised and thus not directly accessible.</p>
              <section>
                <h3>7.1 Right of access</h3>
                <p>The user has the right to request access for stored personal data. The user has in-app access to all explicitly provided data.</p></section>
                <section>
                <h3>7.2 Right to rectification</h3>
                <p>The user has the right to request the rectification of any incorrect personal data.</p></section>
                <section>
                <h3>7.3 Right to erasure (‘right to be forgotten’)</h3>
                <p>The user has the right to request the removal of any personal data.</p></section>
                <section>
                <h3>7.4 Right to restriction of processing</h3>
                <p>The user has the right to restrict processing of their personal data.</p></section>
                <section>
                <h3>7.5 Right to object</h3>
                <p>The user has the right to object to the processing of their personal data.</p></section>
                <section>
                <h3>7.6 Right to data portability</h3>
                <p>The user has the right to request access for personal data in machine readable form.</p></section>
                <section>
                <h3>7.7 Right to lodge complaint to supervisory authority</h3>
                <p>The user has the right to lodge a complaint to a supervisory authority about the manner in which the controller handles the user’s personal data. In Finland, The Data Protection Ombudsman acts as the national supervisory authority.</p></section>
                <section>
                <h3>7.8 Consent withdrawal</h3>
                <p>The user has the right to withdraw their consent at any given time.</p></section>
                <section>
                <h3>7.9 Law enforcement</h3>
                <p>In cases where the user’s request for data modification or deletion is in conflict with any applicable law, the law in question will always override the individual users rights, and can be used as a reason to deny the user’s request in any such case.</p></section>
                </section>
                <section>
                <h2>8. Policy changes</h2>
                <p>RND Works Oy has the right to make changes to this policy without a separate notification to the user in cases where the scope of personal data processing does not change. An up-to-date version of the policy can always be found at the RND Works website.</p></section>
            </article>
          </div>
        </Cell>
      </Grid>
    )
  }

  renderFooter = () => {
    const currentYear = new Date().getFullYear();

    return (
      <footer>
        <Grid className="display">
          <Cell small={12}>
            <p>
              <a href="https://rnd.works/fi">RND Works</a><br/>
              Silkkitehtaantie 5 C<br/>
              01300 Vantaa<br/>
              Y-tunnus: 2742151-8
            </p>
            <div className="social">
              <a href="https://www.facebook.com/RNDWorksInc/"><ion-icon name="logo-facebook"></ion-icon></a>
              <a href="https://www.instagram.com/rnd.works"><ion-icon name="logo-instagram"></ion-icon></a>
              <a href="https://twitter.com/RNDWorksInc/"><ion-icon name="logo-twitter"></ion-icon></a>
            </div>
            &copy; { currentYear } <a href="https://rnd.works/fi">RND Works Oy</a>
          </Cell>
        </Grid>
      </footer>
    )
  }

  render() {
    const hero = 'url(' + heroImage + ')';
    const secondBackground = 'url(' + secondBackgroundImage + ')';

    return (
      <div className="App">
        {
          (() => {
            if (this.state.readTermsVisible === true) {
              return this.renderTerms();
            } else {
              return (
                <div className="main-content-container" style={{ backgroundImage: hero }}>
                  <div>
                    <a href="https://rnd.works/fi"><img className="logo-image" alt="RND Works logo" src={logoImage} /></a>
                  </div>
                  <div className="main-section-backround-wrapper" style={{ backgroundImage: secondBackground }}>
                    <section className="main-content-section">
                      <Grid className="display">
                        <Cell small={12} large={12}>
                          <div className="round-card main">
                            <h1>Ota React Native haltuun</h1>
                            <p className="lead">
                              Meidän mielestämme React Nativen avulla mobiilisovellusten tekeminen on oikeasti aika helppoa. Olemme tehneet niitä jo vuosia. 
                            </p>
                            <p className="lead">
                              Onko teillä aikeita laajentaa osaamistanne React Nativen pariin? Hyvä päätös. Palkkaatte ehkä uuden työntekijän tai ulkoistatte sovelluksen kehityksen. Mutta kuinka hyvin talossa jo oleva väki pystyy keskustelemaan itsevarmasti React Nativeen liittyvissä asioissa? Miten he pääsevät siihen pisteeseen, että voivat rohkeasti esittää kysymyksiä eivätkä pelkää tulevansa viedyksi ohjelmistotoimittajien kanssa kuin pässiä narussa?
                            </p>
                          </div>
                        </Cell>
                      </Grid>
                    </section>
                    <section>
                      <Grid className="display">
                        <Cell small={12} medium={6} large={6}>
                          <div className="round-card secondary left">
                            <h2>Koulutuksen<br/>sisältö</h2>
                            <p>
                            Olemme luoneet koulutuksen, jonka avulla kuka tahansa voi ymmärtää… 
                            </p>
                            <ul>
                              <li>miten React Native toimii ja kuinka sillä rakennetaan sovelluksia</li>
                              <li>miten ulkoasu rakennetaan</li>
                              <li>miten saamme sovelluksen nappaamaan saapuvan puhelun numeron ja hakemaan tietoja rajapinnan avulla tietokannasta</li>
                            </ul>
                          </div>
                        </Cell>
                        <Cell small={12} medium={6} large={6}>
                          <div className="round-card secondary right">
                          <h2>Mitä osaan tehdä koulutuksen jälkeen?</h2>
                            <p>
                              Teemme sovelluksen, joka kertoo onko soittaja myyjä vai liidi. Koulutuksen jälkeen osallistujille ovat tuttuja perustekniikat ja jotkut yleisiin ratkaisuihin liittyvät pulmat. Koulutuksen jälkeen kenenkään ei tarvitse pelätä termejä komponentti, API tai kolmannen osapuolen kirjasto.
                            </p>
                            <p>
                              React Nativen monialustaisuuden hyödyt tulevat selviksi, eikä tule tehtyä ostovirheitä natiivisovellusten kanssa. 
                            </p>
                          </div>
                        </Cell>
                        <Cell small={12} medium={6} large={6}>
                          <div className="round-card secondary left">
                          <h2>Kokeneet kouluttajat palveluksessasi</h2>
                            <p>
                              Kouluttamaan tulee yksi maan kokeneimmista React Native devaajista, joka osaa vastata kinkkisiinkin kysymyksiin. Toinen kouluttajista on opettamisen ammattilainen, joka osaa tehdä asiasta helposti lähestyttävän myös aivan aloittelijoille. 
                            </p>
                            <p>
                              Lähtötasosta riippumatta koulutus pidetään juuri teille sopivalla vaikeusasteella. Koulutus on suunniteltu suomeksi, mutta voidaan tarpeen mukaan pitää myös englanniksi. 
                            </p>
                          </div>
                        </Cell>
                        <Cell small={12} medium={6} large={6} className="pricing">
                          <div className="round-card right">
                          <h2>Ilmoittaudu koulutukseen</h2>
                            <p>
                              Ostaminen on hyvin yksinkertaista: varmistamme vain päivämäärän ja laskutusosoitteen.
                            </p>
                            <p>
                              Kiinteällä <strong>5000€</strong> hinnalla saatte luoksenne päivän kestävän koulutuksen.
                            </p>
                            <h3>
                              Ota yhteyttä
                            </h3>
                            <a href="mailto:info@rnd.works?subject=React Native -koulutus" className="cta">info@rnd.works</a>
                          </div>
                        </Cell>
                      </Grid>
                    </section>
                  </div>
                  <section className="company-info">
                    { this.renderFooter() }
                  </section>
                  {
                    (() => {
                      if (this.state.cookiesAccepted === false) {
                        return (
                          <div className="cookie-notice">
                            <Grid className="display">
                              <Cell small={12} large={12}>
                                <div className="cookie-notice-contents">
                                  <div className="cookie-notice-text">
                                    Käytämme evästeitä varmistaaksemme parhaan mahdollisen käyttökokemuksen sivuillamme.
                                  </div>
                                  <button className="cta" onClick={ () => {
                                    this.setState({ cookiesAccepted: true });
                                    try { document.cookie = "rnd_training_cookies_accepted=yes" } catch(e) { console.log(e) }
                                    // Initialize Google Analytics

                                    this.initGA();

                                  }}>OK</button>
                                  <button onClick={ () => { this.setState({ readTermsVisible: true }); window.scrollTo(0,0) } }>Lue lisää</button>
                                </div>
                              </Cell>
                            </Grid>
                          </div>
                        )
                      }
                    })()
                  }
                </div>
              )
            }
          })()
        }
      </div>
    );
  }
}

export default App;
